import React from "react"
import Layout from "../components/layout"
import HeroImage from "../content/assets/images/about-dermot-kilroy.jpeg"
import { graphql } from "gatsby"

export default ({ data }) => {
    return (
        <Layout>
            
            <div className="relative bg-white overflow-hidden">
                <div
                    className="hidden lg:block lg:absolute lg:inset-0"
                    aria-hidden="true"
                >
                    <svg
                        className="absolute top-0 left-1/2 transform translate-x-64 -translate-y-8"
                        width={640}
                        height={784}
                        fill="none"
                        viewBox="0 0 640 784"
                    >
                        <defs>
                            <pattern
                                id="9ebea6f4-a1f5-4d96-8c4e-4c2abf658047"
                                x={118}
                                y={0}
                                width={20}
                                height={20}
                                patternUnits="userSpaceOnUse"
                            >
                                <rect
                                    x={0}
                                    y={0}
                                    width={4}
                                    height={4}
                                    className="text-gray-200"
                                    fill="currentColor"
                                />
                            </pattern>
                        </defs>
                        <rect
                            y={72}
                            width={640}
                            height={640}
                            className="text-gray-50"
                            fill="currentColor"
                        />
                        <rect
                            x={118}
                            width={404}
                            height={784}
                            fill="url(#9ebea6f4-a1f5-4d96-8c4e-4c2abf658047)"
                        />
                    </svg>
                </div>

                <div className="relative pt-6 pb-16 sm:pb-24 lg:pb-32">
                    <main className="mt-6 mx-auto max-w-7xl px-4 sm:mt-24 sm:px-6 lg:mt-6">
                        <div className="lg:grid lg:grid-cols-12 lg:gap-8">
                            <div className="sm:text-center md:max-w-2xl md:mx-auto lg:col-span-12 lg:text-left lg:text-lg xl:text-xl text-gray-500">
                                <h1>
                                    <span className="mt-1 block text-4xl tracking-tight font-extrabold sm:text-5xl xl:text-6xl">
                                        <span className="block text-gray-900"> Hello there!,</span>
                                        <span className="block text-indigo-600">I'm Dermot Kilroy</span>
                                    </span>
                                </h1>
                                <div className="flex flex-row py-8">
                                    <div className="mt-3 text-base  sm:mt-5 sm:text-xl ">
                                        <div className="w-10/12">
                                            <p className="mb-4">
                                                I've been a Software Engineering Manager since 2016. 
                                            </p>
                                            <p className="mb-4">
                                                Prior to that I was a Software Engineer for 12 years where I gained hands on experience of TDD, DDD and Continuous Dellivery. 
                                            </p>
                                            <p className="mb-4">
                                                I'm currently based in Exeter, UK with my wife, 3 kids, 3 guenea pigs and 1 dog.
                                            </p>
                                        </div>
                                    </div>
                                    <div className="w-6/12">
                                        <img src={HeroImage} alt="about dermot kilroy" className="shadow-lg rounded-full max-w-full h-auto align-middle border-none" />
                                    </div>
                                </div>
                                <div className="mt-8">
                                    <h2 className="mt-1 mb-2 block text-4xl tracking-tight font-bold sm:text-5xl xl:text-6xl">I'm on a mission</h2>
                                    <p className="mb-4">
                                        To provide a working environment that motivates people to smash through their limitations.  I do this by coaching the people I manage to take responsibility for the decisions they make and the actions they take. 
                                    </p>
                                    <p className="mb-4">
                                        I believe that everyone is presented with opportunities to be a leader every day. My job is to help them see the opportunity and then to grasp it each and every time. 
                                    </p>
                                    <p className="mb-4">
                                        I recognise that growth is an uncomfortable path to take so I partner with people on the journey. Seeing people surpass their own expectations to do great things makes me happy. 
                                    </p>
                                </div>
                            </div>
                            <div className="mt-6 relative sm:max-w-lg sm:mx-auto lg:mt-0 lg:max-w-none lg:mx-0 lg:col-span-6 lg:flex lg:items-center">
                            </div>
                        </div>
                    </main>
                </div>
            </div>
        </Layout>
    )
}

